import React from 'react';
import PropTypes from 'prop-types';
import MarkdownRenderer from '../../MarkdownRenderer';
import { colors } from '../../../styles/options';
import ButtonGroup from '../../Button/ButtonGroup';

const GeneralHero = ( { heading, htmlInput, text, background, buttons } ) => {
	return <div dangerouslySetInnerHTML={{ __html: htmlInput }}/>;
};

GeneralHero.defaultProps = {
	heading: 'Hello World',
	text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero facere aperiam ratione, minus dolores itaque, rerum nam voluptates voluptas sunt architecto molestias excepturi animi ab eaque, fuga inventore possimus cumque!',
	background: 'lightened-200',
	buttons: []
};

GeneralHero.propTypes = {
	heading: PropTypes.string,
	text: PropTypes.string,
	htmlInput: PropTypes.string,
	background: PropTypes.oneOf( colors.splice( 0, colors.length - 3 ) ),
	buttons: PropTypes.object
};

export default GeneralHero;
